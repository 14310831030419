const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://sniszdalbi.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://6sbwlegt3i.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'taxonomy-company-1.11.1',
    HOSTNAME: 'https://company.staging.saas.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.saas.forwoodsafety.com',
    WEBSOCKET: 'wss://8hbdhbgchl.execute-api.us-west-2.amazonaws.com/staging'
  },
};

export default config;